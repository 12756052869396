<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form
        @submit.prevent="validationForm"
      >
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
              label="Старый пароль"
              label-for="account-old-password"
            >
              <validation-provider
                #default="{ errors }"
                name="oldpasswordpassword"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="passwordValueOld"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Старый пароль"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              label="Новый пароль"
            >
              <validation-provider
                #default="{ errors }"
                name="accountnewpassword"
                rules="required"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="account-new-password"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Новый пароль"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              label="Повторите новый пароль"
            >
              <validation-provider
                #default="{ errors }"
                name="retypepassword"
                rules="required|confirmed:accountnewpassword"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="RetypePassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Новый пароль"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              type="submit"
            >
              Изменить
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
            >
              Очистить
            </b-button>
          </b-col>
        <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import Ripple from 'vue-ripple-directive'

import { required, confirmed } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'Заполните поле',
})
extend('confirmed', {
  ...confirmed,
  message: 'Не совпадает с полем "Новый пароль"',
})

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      required,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    validationForm() {
      const vm = this
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // console.log(this.$refs.simpleRules)
          useJwt.changePassword({
            password: this.passwordValueOld,
            newpassword: this.newPasswordValue,
            newpasswordconfirm: this.RetypePassword,
          })
            .then(response => {
              if (!response.data.Data.error) {
                vm.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Пароль изменён',
                    icon: 'KeyIcon',
                    variant: 'success',
                    text: 'Теперь Вы можете использовать новый пароль для входа в архив',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Ошибка',
                    icon: 'BellIcon',
                    text: response.data.Data.error,
                  },
                })
              }
            })
            .catch(p1 => {
              // console.log('error', p1)
              try {
                if (p1.response.Date.data) {
                  this.$refs.registerForm.setErrors(p1.response.Date.data.error)
                } else {
                  this.$refs.registerForm.setErrors('Сервер не доступен, повторите попытку позднее')
                }
              } catch {
                this.$refs.registerForm.setErrors(p1)
              }
            })
        }
      })
    },
  },
}
</script>
<style>
[dir] .was-validated .form-control:invalid + .input-group-append .input-group-text,
[dir] .form-control.is-invalid + .input-group-append .input-group-text{
  border-color: #ea5455 !important;
}
</style>
